
import '.././App.css';
import notify_ok from '.././images/notification_ok.svg'
import notify_close from '.././images/notify_close.svg'

function NotificationMessage(props) {
    if(props?.name !== 'liveNotification'){
        setTimeout(() => {
            props?.setOpenNotify(false)
        }, 5000);
    }

    return (
        <div className={(props?.openNotify === true) ? 'notification notificationMessage notificationActive' : 'notification notificationMessage'}>
            <img className='notifyStatus notifyImg' src={(props?.type === 'success') ? notify_ok : ''} alt="Notify Status" />
            <p className="notificationTitle">{props?.title}</p>
            <img onClick={e => props?.setOpenNotify(false)} className='notifyClose' src={notify_close} alt="Close Notify" />
        </div>
    );
}

export default NotificationMessage;