import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import axios from "axios";

import HomePageContent from "./Pages/HomePageContent";
import ContactPageContent from "./Pages/ContactPageContent";
import Notification from "../Components/Notification";
import Loader from "../Components/Loader/Loader";
import ImageEditor from "../Components/ImageEditor/ImageEditor";
import SingleDeletePage from "../Components/DeleteModal/SingleDeletePage";
import AddCategoryPage from "../Components/Modal/AddCategoryPage";
import UploadMediaPopup from "../Components/Modal/UploadMediaPopup";
import Error from "./Error";

import { dataURLtoFile } from "../utils/functions";

import send from ".././images/send.svg";
import plus from ".././images/plus_author.svg";
import link from ".././images/Settings/link.svg";
import linkExternal from ".././images/link-external.svg";
import coverImg from ".././images/picture.svg";
import featured from ".././images/featured.svg";
import iconDropDown from ".././images/icondropdown.svg";
import dots from ".././images/dots.svg";
import search from ".././images/icon-search.svg";

import ".././App.css";
import NotificationMessage from "../Components/NotificationMessage";
import { EditorComponent } from "../Components/TiptapEditor/Editor";
function convertToSlug(str) {
  // Convert the string to lowercase
  let slug = str.toLowerCase();

  // Remove starting and ending spaces
  slug = slug.trim();

  // Replace special characters except for dash with an empty string
  slug = slug.replace(/[^a-z0-9\s-]/g, '');

  // Replace spaces with a single hyphen "-"
  slug = slug.replace(/\s+/g, '-');

  return slug;
}

function NewPage(props) {
  const fileInputRef = useRef(null);

  const [featureImage, setFeatureImage] = useState(null);
  const [saveFeatureImage, setSaveFeatureImage] = useState(null);
  const [oldImage, setOldImage] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [loadingFeatureImg, setloadingFeatureImg] = useState(false);
  const [replaceImgTimeLoading, setReplaceImgTimeLoading] = useState(false);
  const [featuredImageModal, setFeaturedImageModal] = useState(false);
  const [replcaeImageZoom, setReplcaeImageZoom] = useState(false);
  const modalRef = useRef(null);
  const [zoom, setZoom] = useState(1);
  const [oldZoom, setOldZoom] = useState(1);
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [isDragging, setIsDragging] = useState(false);
  const [isSlugChanged, setIslugChanged] = useState(false);
  const [pageTitle, setPageTitle] = useState('');
  const [status, setStatus] = useState("");
  const [dbPageTitle, setdbPageTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDraftLoading, setIsDraftLoading] = useState(false);
  const [validateTitle, setValidateTitle] = useState(false);
  const [validateEditorContent, setValidateEditorContent] = useState(false);
  const [validateImage, setValidateImage] = useState(false);
  const [apiCall, setApiCall] = useState(false);

  const [isHomepage, setIsHomepage] = useState(false);
  const [isContactpage, setIsContactpage] = useState(false);
  const [showPublication, setShowPublication] = useState(false);
  const [homepageData, setHomepageData] = useState({});
  const [contactpageData, setContactpageData] = useState({});
  const [updatedContactpageData, setUpdatedContactpageData] = useState({
    pageText: '',
    publicAddress: '',
    publicEmail: '',
    showSocialNetwork: false,
    showContactForm: false,
    toReceiveEmail: '',
    phoneNumber: {
      dialCode: '91',
      countryCode: 'in',
      phoneNumber: '',
      value: ''
    },
    featured_image: {}
  });
  const [validationContactpageData, setValidationContactpageData] = useState({
    publicEmailValid : true,
    receiveEmailValid: true,
    showError: false
  });

  
  const [showUploadMediaPopup, setShowUploadMediaPopup] = useState(false);
  const [uploadMediaPopupFor, setUploadMediaPopupFor] = useState('');
  const [uploadMediaPopupTab, setUploadMediaPopupTab] = useState('upload');
  const [selectedFeatureImage, setSelectedFeatureImage] = useState('');
  const [selectMediaLoading, setSelectMediaLoading] = useState(false);
  const [selectedFeatureImageData, setSelectedFeatureImageData] = useState('');
  
  const [featureCropedImageId, setFeatureCropedImageId] = useState('');
  const [featureImageId, setFeatureImageId] = useState('');

  // Start Search
  const [values, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [showCategoryField, setShowCategoryField] = useState(false);
  const [searchCategory, setSearchCategory] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [canAccess, setCanAccess] = useState(true);
  const [canChkAccess, setCanChkAccess] = useState(false);

  const [openNotify, setOpenNotify] = useState(false);
  const [openNotifyDraft, setOpenNotifyDraft] = useState(false);

  const [published, setPublished] = useState(false);
  const [draft, setDraft] = useState(false);
  const [isDraftOpen, setIsDraftOpen] = useState(false);
  const [isPublishedOpen, setIsPublishedOpen] = useState(false);
  const [pageImage, setPageImage] = useState('');
  const [data, setData] = useState([]);
  const [draftNotify, setDraftNotify]= useState(false)
  const [categoriesNotify, setCategoriesNotify] = useState(false)

  const dotsRef = useRef(null);

  const profileSlug = localStorage.getItem('userProfileSlug');
  const baseUrl = `https://jourmal-next-front.vercel.app/${profileSlug}/`;
  
  var notificationTimeoutId = '';

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const categoriesData = await axios.get(
        `${process.env.REACT_APP_API_URL}/categories/list/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
      );

      if( categoriesData.status == 200 ) {        
        const categoriesArray = categoriesData.data.result.map(item => item.name);
        setCategories(categoriesArray);
      }
      
    } catch (error) {
      console.log(error, "error");
    }
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        (!modalRef.current.contains(event.target) &&
          featuredImageModal === false) ||
        event.target.className === "overlayModal"
      ) {
        setFeaturedImageModal(false);
        setZoom(oldZoom);
        setOldImage(newUploadedImage);
      }
    };

    if (featuredImageModal) {
      window.addEventListener("click", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [featuredImageModal]);

  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen3, setIsOpen3] = useState(true);
  const [isOpen4, setIsOpen4] = useState(true);

  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
  };

  const toggleDropdown3 = () => {
    setIsOpen3(!isOpen3);
  };

  const toggleDropdown4 = () => {
    setIsOpen4(!isOpen4);
  };

  const [uploadedImage, setUploadedImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [newUploadedImage, setNewUploadedImage] = useState(null);

  // props.setActivePage("Page");

  const [editorContent, setEditorContent] = useState("");
  const [updatedContent, setUpdatedContent] = useState("");

  const [url, setUrl] = useState("");

  const [allPublicationList, setAllPublicationList] = useState([]);

  const [origPublicationList, setOrigPublicationList] = useState([]);

  useEffect(() => {
    if( isHomepage ) {
        setUrl("");
    }

    if( isContactpage ) {
        setUrl("contact");
    }
},[isHomepage, isContactpage])

  useEffect(() => {
    setOrigPublicationList(allPublicationList);
  }, [allPublicationList]);

  const [publicationList, setPublicationList] = useState([]);

  const [selectPublication, setSelectPublication] = useState(false);

  const [publicationInput, setPublicationInput] = useState("");

  useEffect(() => {
    if (publicationInput.length === 0) {
      setOrigPublicationList(allPublicationList);
    } else {
      var result = [];
      var input = publicationInput.toLowerCase();
      origPublicationList.forEach((element) => {
        var title = element.title.toLowerCase();

        if (title) {
          if (title.startsWith(input) === true) {
            result.push(element);
          }
        }
      });

      if (result != []) {
        setOrigPublicationList(result);
      } else {
        setOrigPublicationList([]);
      }
    }
  }, [publicationInput]);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (selectPublication && ref.current && !ref.current.contains(e.target)) {
        setSelectPublication(false);
        setIsDraftOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [selectPublication]);

  useEffect(() => {
    if(selectedFeatureImage !== '') {
      setBlobData();
    }
    
  }, [selectedFeatureImage]);

  const setBlobData = async () => {
    setSelectMediaLoading(true);
    try {
      const imageData = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/fetchImageData/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
        selectedFeatureImage,
        { responseType: 'blob' }
      );
      
      const imageUrl = URL.createObjectURL(imageData.data);

      setZoom(1);

      setUploadedImage(imageUrl);
      setImageFile(null);
      setOldImage(imageUrl);

      if(featureImage === null || featureImage === '') {
        setFeatureImage(imageUrl);
      }

      setloadingFeatureImg(false);
      setFeaturedImageModal(true);
      setReplcaeImageZoom(false);

      setPageImage(imageUrl);

      setSelectedFeatureImageData(selectedFeatureImage);

      setSelectMediaLoading(false);
      setShowUploadMediaPopup(false);
      setUploadMediaPopupFor('');

    } catch (error) {
      setSelectMediaLoading(false);
      console.log({error:error, message: error.message})
    }
    setSelectMediaLoading(false);
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (e.target != null && isPublishedOpen && (!e.target.classList.contains('publish-li') && !e.target.classList.contains('newpageMoreButtons'))) {
        setIsPublishedOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isPublishedOpen]);

  const handleClick = () => {
    setFeaturedImageModal(!featuredImageModal);
    // setFeatureImage(saveFeatureImage);
    var divElement = document.getElementById("newpageImageUpload");
    divElement.classList.add("imageUploaded");
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
    if (!isDragging) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    var divElement = document.getElementById("newpageImageUpload");

    var extraHeight = (featureImage) ? 2 : 0;

    divElement.style.height = (divElement.clientHeight + extraHeight) + "px";
    divElement.style.minHeight = (divElement.clientHeight + extraHeight) + "px";

    divElement.classList.remove("imageUploaded");
    setloadingFeatureImg(true);

    var TempReplcaeImageZoom = replcaeImageZoom
    var TempReplaceImgTimeLoading = replaceImgTimeLoading

    if (featureImage) {
      setReplcaeImageZoom(true);
      setReplaceImgTimeLoading(false);

      TempReplcaeImageZoom = true;
      TempReplaceImgTimeLoading = false;
    }

    if (TempReplcaeImageZoom === true) {
      setOldZoom(1);
      setZoom(1);
    } else {
      setZoom(oldZoom);
    }

    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        if (file) {
          setUploadedImage(file);
          setImageFile(file);
          setPageImage(file);
          TempReplcaeImageZoom !== true && setFeatureImage(reader.result);
          setOldImage(reader.result);
        }
      };
    };
    if (file) {      
      setPageImage(file);
      setTimeout(() => {
        reader.readAsDataURL(file);
        setDragging(false);
        setUploadedImage(file);
        setImageFile(file);
        setloadingFeatureImg(false);
        var divElement = document.getElementById("newpageImageUpload");
        divElement.classList.add("imageUploaded");
        divElement.classList.add("imageUploaded-wrap");
        setOldImage(file);
        setFeaturedImageModal(true);
        setReplcaeImageZoom(false);
        setReplaceImgTimeLoading(false);

        // divElement.style.height = 'auto';
        // divElement.style.minHeight = 'unset';
      }, 4000);
    } else {
      setloadingFeatureImg(false);

      // divElement.style.height = 'auto';
      // divElement.style.minHeight = 'unset';
    }
    setIsDragging(false);
  };

  const handleFileUpload = (e) => {
    var divElement = document.getElementById("newpageImageUpload");

    var extraHeight = (featureImage) ? 2 : 0;

    divElement.style.height = (divElement.clientHeight + extraHeight) + "px";
    divElement.style.minHeight = (divElement.clientHeight + extraHeight) + "px";

    divElement.classList.remove("imageUploaded");
    setloadingFeatureImg(true);
    if (replcaeImageZoom === true) {
      setOldZoom(1);
      setZoom(1);
    } else {
      setZoom(oldZoom);
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const image = new Image();
      image.src = reader.result;
      image.onload = () => {
        replcaeImageZoom !== true && setFeatureImage(reader.result);
        setOldImage(reader.result);
      };
    };
    if (file) {
      reader.readAsDataURL(file);

      setUploadedImage(null);
      setImageFile(null);
      setPageImage(file);

      setTimeout(() => {
        reader.readAsDataURL(file);
        setDragging(false);
        setloadingFeatureImg(false);
        setUploadedImage(file);
        setImageFile(file);
        var divElement = document.getElementById("newpageImageUpload");
        divElement.classList.add("imageUploaded");
        divElement.classList.add("imageUploaded-wrap");
        setFeaturedImageModal(true);
        setReplcaeImageZoom(false);

        // divElement.style.height = 'auto';
        // divElement.style.minHeight = 'unset';
      }, 4000);
    } else {
      setloadingFeatureImg(false);
      // divElement.style.height = 'auto';
      // divElement.style.minHeight = 'unset';
    }
  };

  const handleImageLoad = () => {
    const img = new Image();
    img.src = featureImage;
    img.onload = () => { };
  };

  const handleReplaceImg = () => {
    // document.getElementById("feature-img").click();
    setShowUploadMediaPopup(true);
    setUploadMediaPopupFor('feature-image');
    setFeaturedImageModal(false);
    setReplcaeImageZoom(true);
    setReplaceImgTimeLoading(false);
  };

  useEffect(() => {
    setZoom(zoom);
  }, [zoom]);

  const handleZoom = (zoom) => {
    setZoom(zoom);
  };

  const handlePosition = (newPosition) => {
    setPosition(newPosition);
  };

  const handleSavePicture = async () => {
    let click = document.getElementById("clickFunction");
    click.click();
    setFeatureImage(featureImage);
    setNewUploadedImage(oldImage);
    setSaveFeatureImage(featureImage);
    setFeaturedImageModal(false);
    setOldZoom(zoom);
  };

  const handleMouseDown = () => {
    const slider = document.getElementById("zoom-range");
    slider.classList.add("slider-cursor");
  };

  const handleMouseUp = () => {
    const slider = document.getElementById("zoom-range");
    slider.classList.remove("slider-cursor");
  };

  const handleTouchStart = () => {
    const slider = document.getElementById("zoom-range");
    slider.classList.add("slider-cursor");
  };

  const handleTouchEnd = () => {
    const slider = document.getElementById("zoom-range");
    slider.classList.remove("slider-cursor");
  };

  const handleCancel = () => {
    if (!saveFeatureImage) {
      var divElement = document.getElementById("newpageImageUpload");
      featureImage && divElement.classList.remove("imageUploaded");
    } else {
      var divElement = document.getElementById("newpageImageUpload");
      featureImage && divElement.classList.add("imageUploaded");
    }
    setOldImage(newUploadedImage);
    setFeaturedImageModal(false);
    setZoom(oldZoom);

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
      setUploadedImage(null);
      setImageFile(null);
    }
  };

  const changeSlug = (value) => {
    // setPageTitle(value);
    if (!isSlugChanged) {
      let urlValue = convertToSlug(value);
      setUrl(urlValue);
    }
  }

  const changeTitle = (e) => {
    //Stop enter button

    if (e.key === 'Enter') {
      e.preventDefault();
      return true;
    }

  }

  const changesTitles = (e) => {
    const text = e.target.innerText.replace(/<[^>]+>/g, '');

    if (text.length <= 120 || e.key === 'Backspace') {
      // setPageTitle(text)
      changeSlug(text);
    } else {
      e.preventDefault();
      return true;
    }
  }

  const handleBlur = (e) => {
    const text = e.target.innerText.replace(/<[^>]+>/g, '');
    setdbPageTitle(text)
  }

  const handlePaste = (e) => {
    e.preventDefault();

    // Get the plain text content from clipboard
    const clipboardData = e.clipboardData.getData('text/plain');

    // Paste the plain text into the contenteditable div
    document.execCommand('insertText', false, clipboardData);
  }
  const [authorId, setauthorId] = useState(JSON.parse(localStorage.getItem("userinfo"))?._id);

  const { id } = useParams()
  const [newPageID, setNewPageID] = useState( null );

  useEffect(() => {

    const user_id = JSON.parse(localStorage.getItem("userinfo"))?._id
    const homagepage_id = btoa(`homepage-${user_id}`);
    const contactpage_id = btoa(`contactpage-${user_id}`);

    if( homagepage_id ===  id) {
      setIsHomepage(true);
      setPublished(true);
      setStatus('Published')
    }

    if( contactpage_id ===  id) {
      setIsContactpage(true);
      setPublished(true);
      setStatus('Published')
    }

    setCanChkAccess(true);
    setNewPageID(id);
  }, [id]);

  useEffect(() => {
    if(canChkAccess) {
      if (id) {
        singlePageList()
      } else {
        setEditorContent('')
        setUpdatedContent('')
        setdbPageTitle('')
        setUrl('')
        setPublicationList([])
        setIsLoading(false)
        setFeatureImage('')
        setDraft(false)
        setPublished(false)
        setIsDraftLoading(false)
        setSaveFeatureImage(null)

        setSelectedCategories([])
      }
      clearTimeout(notificationTimeoutId)
    }
  }, [isHomepage, isContactpage, canChkAccess,id]);


  const [messages, setMessages] = useState("");
    const [subscriberAddedNotify, setSubscriberAddedNotify] = useState(false);
    const user_id = JSON.parse(localStorage.getItem("userinfo"))?._id
    useEffect(() => {
        const webSocket = new WebSocket('ws://localhost:8080');
    
        webSocket.onopen = () => {
        //   console.log('Connected to WebSocket server');

        };
    
        webSocket.onmessage = (event) => {
            const parsedData = JSON.parse(event.data);
            if(parsedData?.author_id === user_id){
                if(parsedData?.message !== "" && parsedData?.message !== undefined){
                    setSubscriberAddedNotify(true);
                    setMessages(parsedData?.message);
                }
            }
        };
    
        return () => {
            webSocket.close();
        };
    }, [messages]);
  const singlePageList = async () => {
    try {
        const userId = JSON.parse(localStorage.getItem("userinfo"))?._id;
        if (isHomepage) {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/getHomepage/${userId}`);
            if (res) {
                let homepagedata = res?.data?.result?.homePageData;
                setHomepageData(homepagedata);
                setCanAccess(true);

                setEditorContent(homepagedata.summary); 
                setUpdatedContent(homepageData.summary)               

                if (homepagedata?.featured_image?.selectedImage) {
                  const imageData = await axios.post(
                      `${process.env.REACT_APP_API_URL}/user/fetchImageData/${userId}`,
                      res?.data?.homepageMedia?.selectedMedia,
                      { responseType: 'blob' }
                  );

                  const imageUrl = URL.createObjectURL(imageData.data);
                  setUploadedImage(imageUrl);
                  setImageFile(null);
                  setNewUploadedImage(imageUrl);
                  setOldImage(imageUrl);
                  setFeatureImageId(homepagedata.featured_image.selectedImage);
                }

                if (homepagedata?.featured_image?.cropedImage) {
                  const imageData = await axios.post(
                      `${process.env.REACT_APP_API_URL}/user/fetchImageData/${userId}`,
                      res?.data?.homepageMedia?.cropedMedia,
                      { responseType: 'blob' }
                  );

                  const imageUrl = URL.createObjectURL(imageData.data);

                  setFeatureImage(imageUrl);
                  setSaveFeatureImage(imageUrl);
                  setFeatureCropedImageId(homepagedata?.featured_image?.cropedImage);

                  setPageImage(imageUrl);
                }
                if(homepagedata?.featured_image?.zoom) {
                  setZoom(homepagedata.featured_image.zoom)
                  setOldZoom(homepagedata.featured_image.zoom)
                }        
                if(homepagedata?.featured_image?.position) {
                  setPosition(homepagedata.featured_image.position)
                }
            }
        } else if (isContactpage) {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/getContactpage/${userId}`);
            if (res) {
                let contactpagedata = res?.data?.result;
                setContactpageData(contactpagedata);
                setCanAccess(true);

                if (contactpagedata?.userMeta?.featured_image?.selectedImage) {
                  const imageData = await axios.post(
                      `${process.env.REACT_APP_API_URL}/user/fetchImageData/${userId}`,
                      contactpagedata.constactMedia.selectedMedia,
                      { responseType: 'blob' }
                  );

                  const imageUrl = URL.createObjectURL(imageData.data);

                  setUploadedImage(imageUrl);
                  setImageFile(null);
                  setNewUploadedImage(imageUrl);
                  setOldImage(imageUrl);
                  setFeatureImageId(contactpagedata.userMeta.featured_image.selectedImage);
                }
                if (contactpagedata?.userMeta?.featured_image?.cropedImage) {
                  const imageData = await axios.post(
                      `${process.env.REACT_APP_API_URL}/user/fetchImageData/${userId}`,
                      contactpagedata.constactMedia.cropedMedia,
                      { responseType: 'blob' }
                  );

                  const imageUrl = URL.createObjectURL(imageData.data);

                  setFeatureImage(imageUrl);
                  setSaveFeatureImage(imageUrl);
                  setFeatureCropedImageId(contactpagedata.userMeta.featured_image.cropedImage);

                  setPageImage(imageUrl);
                }                
                if(contactpagedata?.userMeta?.featured_image?.zoom) {
                  setZoom(contactpagedata.userMeta.featured_image.zoom)
                  setOldZoom(contactpagedata.userMeta.featured_image.zoom)
                }        
                if(contactpagedata?.userMeta?.featured_image?.position) {
                  setPosition(contactpagedata.userMeta.featured_image.position)
                }
            }
        } else {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/pages/${id}?userId=${userId}`);
            if (res) {
              const pageData = res?.data?.result;
              setdbPageTitle(pageData.title);
              setStatus(pageData.status);
              setEditorContent(pageData.description);
              setUpdatedContent(pageData.description);
              setUrl(pageData.url_address);
              setData(pageData);

              if (pageData.status === "Published") {
                  setPublished(true);
              }
              if (pageData.status === "Draft") {
                  setDraft(true);
              }

              let publicationListData = [];
              let selectedCat = [];
              pageData.categories?.forEach((item) => {
                  publicationListData.push({ title: item });
                  selectedCat.push(item);
              });

              if (pageData?.featured_image?.selectedImage) {
                  const imageData = await axios.post(
                      `${process.env.REACT_APP_API_URL}/user/fetchImageData/${userId}`,
                      pageData.featureImageData.selectedMedia,
                      { responseType: 'blob' }
                  );

                  const imageUrl = URL.createObjectURL(imageData.data);

                  setUploadedImage(imageUrl);
                  setImageFile(null);
                  setNewUploadedImage(imageUrl);
                  setOldImage(imageUrl);
                  setFeatureImageId(pageData.featured_image.selectedImage);
              }
              if (pageData?.featured_image?.cropedImage) {
                const imageData = await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/fetchImageData/${userId}`,
                    pageData.featureImageData.cropedMedia,
                    { responseType: 'blob' }
                );

                const imageUrl = URL.createObjectURL(imageData.data);

                setFeatureImage(imageUrl);
                setSaveFeatureImage(imageUrl);
                setFeatureCropedImageId(pageData.featured_image.cropedImage);

                setPageImage(imageUrl);
              }
              if(pageData?.featured_image?.zoom) {
                setZoom(pageData.featured_image.zoom)
                setOldZoom(pageData.featured_image.zoom)
              }
      
              if(pageData?.featured_image?.position) {
                setPosition(pageData.featured_image.position)
              }

              setPublicationList(publicationListData);
              setSelectedCategories(selectedCat);
              setCanAccess(true);
            } else {
                setCanAccess(false);
            }
        }
    } catch (err) {
        setCanAccess(false);
        console.log(err);
    }
}



  const handleChangeToDraft = async () => {
    if (published) {
      setIsLoading(true)
      let selectedImage = featureImageId;
      let featureCropedImage = featureCropedImageId;

      const featureImageData = await getFeatureImageData(newPageID)

      selectedImage = featureImageData?.selectedImage || selectedImage;
      featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;

      const newPageData = {
        description: updatedContent || editorContent,
        title: dbPageTitle,
        author_id: authorId,
        url_address: url,
        categories: selectedCategories,
        status: "Draft",
        previous_status: "Draft",
        featured_image: {
          selectedImage: selectedImage,
          cropedImage: featureCropedImage,
          position: position,
          zoom: zoom
        }
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/pages/updatepage/${newPageID}`,
        newPageData
      )
        .then((res) => {
          setIsLoading(false)
        })
        .catch((err) => {
          setIsLoading(false)
        })
      setOpenNotifyDraft(true)
      //close the popup

      clearTimeout(notificationTimeoutId) 
      notificationTimeoutId = setTimeout(() => {
        setOpenNotifyDraft(false)
      }, 15000);
      setPublished(false);
      setDraft(true);
      setIsPublishedOpen(false);
      setIsDraftOpen(false);
      setStatus("Draft")
    }
  };

  const handleChangeToPublish = async () => {
    setIsLoading(true)
    let selectedImage = featureImageId;
    let featureCropedImage = featureCropedImageId;

    const featureImageData = await getFeatureImageData(newPageID)

    selectedImage = featureImageData?.selectedImage || selectedImage;
    featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;
    const newPageData = {
      description: updatedContent||  editorContent,
      title: dbPageTitle,
      author_id: authorId,
      url_address: url,
      categories: selectedCategories,
      status: 'Published',
      previous_status: 'Published',
      featured_image: {
        selectedImage: selectedImage,
        cropedImage: featureCropedImage,
        position: position,
        zoom: zoom
      }
    }

     await axios.post(
      `${process.env.REACT_APP_API_URL}/pages/updatepage/${newPageID}`,
      newPageData
    )
      .then((res) => {
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      })
    setPublished(true);
    setDraft(false);
    setOpenNotify(true)
    setIsPublishedOpen(false);
    setIsDraftOpen(false);
    setStatus("Published")

    clearTimeout(notificationTimeoutId) 
    notificationTimeoutId = setTimeout(() => {
      setOpenNotify(false)
    }, 15000);

  };

  const [isPopupOpenDeleteAccount, setIsPopupOpenDeleteAccount] = useState(false);

  const handelSingleDelete = () => {
    setIsDraftOpen(false);
    setIsPublishedOpen(false);
    setIsPopupOpenDeleteAccount(true);
    document.body.classList.add('hidden-message');
  }

  const closeAccountDeletedPopup = () => {
    setIsPopupOpenDeleteAccount(false);
    document.body.classList.remove('hidden-message');
  }

  const closeCategoryField = (action) => {
    
    setShowCategoryField(false);
    document.body.classList.remove('hidden-message');

    setTimeout(() => {
      scrollToBottom();
    }, 100);
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (e.target != null && isPopupOpenDeleteAccount && (e.target.classList.contains('welcomeOverlay'))) {
        setIsPopupOpenDeleteAccount(false);
      }

      if (e.target != null && isDraftOpen && !(e.target.classList.contains('publishedButtonsList')) && !(e.target.classList.contains('publish-li'))) {
        setIsDraftOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isPopupOpenDeleteAccount, isDraftOpen]);

  const handleDraft = async () => {

    if (apiCall === false) {
      draftValidateFields();
      setIsLoading(true)
      setApiCall(true)

      let selectedImage = featureImageId;
      let featureCropedImage = featureCropedImageId;

      const featureImageData = await getFeatureImageData(newPageID)

      selectedImage = featureImageData?.selectedImage || selectedImage;
      featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;

      const newPageData = {
        description: updatedContent || editorContent,
        title: dbPageTitle,
        author_id: authorId,
        url_address: url,
        categories: selectedCategories,
        status: "Draft",
        previous_status: "Draft",
        featured_image: {
          selectedImage: selectedImage,
          cropedImage: featureCropedImage,
          position: position,
          zoom: zoom
        }
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/pages/updatepage/${newPageID}`,
        newPageData
      )
        .then((res) => {
          setIsLoading(false)
          setOpenNotifyDraft(true)
          
          clearTimeout(notificationTimeoutId)
          notificationTimeoutId = setTimeout(() => {
            setOpenNotifyDraft(false)
          }, 15000);

          if (isLoading === false) {
            setApiCall(false)
          }
        })
        .catch((err) => {
          setIsLoading(false)
          if (isLoading === false) {
            setApiCall(false)
          }
        })
    }
  }

  const handlePublish = async () => {

    if (apiCall === false) {
      setIsLoading(true)
      setApiCall(true)

      let selectedImage = featureImageId;
      let featureCropedImage = featureCropedImageId;

      const featureImageData = await getFeatureImageData(newPageID)

      selectedImage = featureImageData?.selectedImage || selectedImage;
      featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;
      
      const newPageData = {
        description: updatedContent || editorContent,
        title: dbPageTitle,
        author_id: authorId,
        url_address: url,
        categories: selectedCategories,
        status: 'Published',
        previous_status: 'Published',
        featured_image: {
          selectedImage: selectedImage,
          cropedImage: featureCropedImage,
          position: position,
          zoom: zoom
        }
      }

      if( isHomepage ) {
        const homepageData = {
          summary: updatedContent || editorContent,
          showPublication:showPublication,
          featured_image: {
            selectedImage: selectedImage,
            cropedImage: featureCropedImage,
            position: position,
            zoom: zoom
          }
        };
        await axios.post(
            `${process.env.REACT_APP_API_URL}/user/updateHomepage/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            homepageData
        ).then((res) => {
          setOpenNotify(true)
          setIsLoading(false)

          if (isLoading === false) {
            setApiCall(false)
          }

          clearTimeout(notificationTimeoutId) 
          notificationTimeoutId = setTimeout(() => {
            setOpenNotify(false)
          }, 15000);
        })
        .catch((error) => {
          if (isLoading === false) {
            setApiCall(false)
          }
          setIsLoading(false)
        });
      }

      if( isContactpage ) {
        const contactpageData = {
          ...updatedContactpageData,
          featured_image: {
            selectedImage: selectedImage,
            cropedImage: featureCropedImage,
            position: position,
            zoom: zoom
          }
        }

        await axios.post(
          `${process.env.REACT_APP_API_URL}/user/updateContactpage/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
          contactpageData
          ).then((res) => {
            setOpenNotify(true)
            setIsLoading(false)

            if (isLoading === false) {
              setApiCall(false)
            }

            clearTimeout(notificationTimeoutId) 
            notificationTimeoutId = setTimeout(() => {
              setOpenNotify(false)
            }, 15000);
          })
          .catch((error) => {
            if (isLoading === false) {
              setApiCall(false)
            }
            setIsLoading(false)
          });
      }

      if( ! isHomepage && ! isContactpage ) {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/pages/updatepage/${newPageID}`,
          newPageData
        )
          .then((res) => {
            setOpenNotify(true)
            setIsLoading(false)

            setStatus('Published');

            if (isLoading === false) {
              setApiCall(false)
            }

            clearTimeout(notificationTimeoutId) 
            notificationTimeoutId = setTimeout(() => {
              setOpenNotify(false)
            }, 15000);
          })
          .catch((error) => {
            if (isLoading === false) {
              setApiCall(false)
            }
            setIsLoading(false)
          })
        }

    }
  }

  const handleChangeURL = (pageID) => {
    const newUrl = '/dashboard/page/' + pageID;  // Replace with the URL you want

    // Change the URL without reloading the page
    window.history.pushState({}, '', newUrl);
  };

  const addData = async (isDraft, status) => {
    if (id) {
      try {
        
        let selectedImage = featureImageId;
        let featureCropedImage = featureCropedImageId;

        const featureImageData = await getFeatureImageData(id)

        selectedImage = featureImageData?.selectedImage || selectedImage;
        featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;
        
        const newPageData = {
          description: updatedContent || editorContent,
          title: dbPageTitle,
          author_id: authorId,
          url_address: url,
          categories: selectedCategories,
          status: status,
          previous_status: status,
          featured_image: {
            selectedImage: selectedImage,
            cropedImage: featureCropedImage,
            position: position,
            zoom: zoom
          }
        }

        await axios.post(
          `${process.env.REACT_APP_API_URL}/pages/updatepage/${id}`,
          newPageData
        )

        clearTimeout(notificationTimeoutId) 
        notificationTimeoutId = setTimeout(() => {
          setOpenNotify(false)
        }, 15000);
      } catch (error) {
        setIsDraftLoading(false);
        setIsLoading(false);
      }
    } else {
      try {
        const newPageData = {
          description: updatedContent || editorContent,
          title: dbPageTitle,
          author_id: authorId,
          url_address: url,
          categories: selectedCategories,
          status: status,
          previous_status: status,
        }

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/pages/addpage`,
          newPageData
        );

        if (response.status = 201) {
          const _pageId = response.data.result._id;
          handleChangeURL(_pageId);
          setNewPageID(_pageId);
          
          let selectedImage = featureImageId;
          let featureCropedImage = featureCropedImageId;

          const featureImageData = await getFeatureImageData(_pageId)

          selectedImage = featureImageData?.selectedImage || selectedImage;
          featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;

          const updatedPageData = { ...newPageData, 
            featured_image: {
              selectedImage: selectedImage,
              cropedImage: featureCropedImage,
              position: position,
              zoom: zoom
          }};

          await axios.post(
            `${process.env.REACT_APP_API_URL}/pages/updatepage/${_pageId}`,
            updatedPageData
          )
        }    
        setIsLoading(false);
        if(response.status === 200){
          if (status === "Draft") {
            setOpenNotifyDraft(true)
            
            clearTimeout(notificationTimeoutId) 
            notificationTimeoutId = setTimeout(() => {
              setOpenNotifyDraft(false)
            }, 15000);
          }
          if (status === "Published") {
            setOpenNotify(true)
  
            clearTimeout(notificationTimeoutId) 
            notificationTimeoutId = setTimeout(() => {
              setOpenNotify(false)
            }, 15000);
          }
        }
        setIsDraftLoading(false);

      } catch (error) {
        setIsDraftLoading(false);
        setIsLoading(false);
      }
    }
  }

  const getFeatureImageData = async (id) => {
    let selectedImage = featureImageId;
    let featureCropedImage = featureCropedImageId;
    
    if( imageFile !== null ) {  
          const imageFormData = new FormData();
          imageFormData.append('files', imageFile); // Append each file individually
          try {
            const res = await axios.post(
              `${process.env.REACT_APP_API_URL}/user/uploadMedia/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
              imageFormData, {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
              }
            );          
            selectedImage = res.data.result[0]._id;
    
            const featureImageFile = await dataURLtoFile(featureImage, "feature-image.jpg");
            const formData = new FormData();
            formData.append('files', featureImageFile);
            formData.append('id', id);
            const uploadedMedia = await axios.post(
              `${process.env.REACT_APP_API_URL}/user/uploadMedia/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
              formData, {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
              }
            );
  
            featureCropedImage = uploadedMedia?.data?.result[0]?._id || featureCropedImage;
  
          } catch (error) {
            console.log(error);
          }
          //(featureImageId !== '' || selectedFeatureImageData?._id) && oldImage !== saveFeatureImage
      } else if((selectedFeatureImageData?._id) || oldImage !== saveFeatureImage) {        
          const featureImageFile = await dataURLtoFile(featureImage, "feature-image.jpg");
  
          const formData = new FormData();
          formData.append('files', featureImageFile);
          formData.append('id', id);
  
          const uploadedMedia = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/uploadMedia/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
        formData, {
          headers: {
                    'Content-Type': 'multipart/form-data'
                }
        }
          );
  
          selectedImage = selectedFeatureImageData?._id || selectedImage;
          featureCropedImage = uploadedMedia?.data?.result[0]?._id || featureCropedImage;
      }
      
    const result = {selectedImage, featureCropedImage}
      
    return result;
  }

  const validateFields = () => {
    if (pageTitle.length > 0) {
      setValidateTitle(false);
    } else {
      setValidateTitle(true);
    }
    if (dbPageTitle.length > 0) {
      setValidateTitle(false);
    } else {
      setValidateTitle(true);
    }
    if ((updatedContent.length > 0) || (editorContent.length > 0)) {
      setValidateEditorContent(false);
    } else {
      setValidateEditorContent(true);
    }
    if (pageImage === '') {
      setValidateImage(true);
    } else {
      setValidateImage(false);
    }
  }

  const draftValidateFields = () => {
    if (pageTitle.length > 0) {
      setValidateTitle(false);
    } else {
      setValidateTitle(true);
    }
    if (dbPageTitle.length > 0) {
      setValidateTitle(false);
    } else {
      setValidateTitle(true);
    }
  }

  useEffect(() => {
    // Use the effect to clear the error message after 2 seconds
    const timer = setTimeout(() => {
      setErrorMessage('');
    }, 2000); 

    return () => {
      clearTimeout(timer);
    };
  }, [errorMessage]);
  
  
  const handleCheckboxChange = (value) => {
    if (selectedCategories.includes(value)) {
      setSelectedCategories(selectedCategories.filter((v) => v !== value));
    } else {
      setSelectedCategories([...selectedCategories, value]);
    }
  };

  const filteredValues = values.filter((value) =>
    value.toLowerCase().includes(searchCategory.toLowerCase())
  );

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {      
      setSearchCategory(inputValue);
      setInputValue('');
    }
  };

  const handleNewCategoryClick = () => {
    // setInputValue(''); // Clear input when creating a new category
    setShowCategoryField(true); // Hide closeLink    
    // setErrorMessage('');
    document.body.classList.add('hidden-message');
  };

  // Function to scroll the ul to the bottom
  const scrollToBottom = () => {
    var categoriesList = document.querySelector('.categories-list');
    var liElements = categoriesList.querySelectorAll('li');

    let totalHeight = ( ( parseInt( liElements.length ) + 1 ) * 22 ) + 5;

    categoriesList.scrollTop = totalHeight;
  };

  // End Search
  useEffect(() => {
    if (featuredImageModal) {
        const handleKeyDown = (e) => {
            const deleteMediaElement = document.getElementById('deleteMedia');
            if (e?.key === 'Enter' && featuredImageModal && deleteMediaElement?.children?.length > 0) {
              handleSavePicture()
            }else if(e?.key === "Escape"){
                setShowUploadMediaPopup(false)
                handleCancel()

            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }
}, [featuredImageModal]);
  return (
    <>
      {
        canAccess ?            
          <section className="dashboard new-page">      
            <div className="container">
              <div className="dashboardTop">
                <div className="newpageContent newpageContentImage">
                  <div className="newpageLeft">
                    {
                      isHomepage && 
                        <HomePageContent homepageData={homepageData} setEditorContent={setEditorContent} validateEditorContent={validateEditorContent} setValidateEditorContent={setValidateEditorContent} showPublication={showPublication} setShowPublication={setShowPublication}  />
                    }

                    {
                      isContactpage && 
                        <ContactPageContent contactpageData={contactpageData} setUpdatedContactpageData={setUpdatedContactpageData} validationContactpageData={validationContactpageData} setValidationContactpageData={setValidationContactpageData}  setShowUpgradeModel={props?.setShowUpgradeModel} planDetails={props?.planDetails}/>
                    }

                    {
                      (! isHomepage && ! isContactpage) && 
                        <>

                        <div className="newpageLeftRow editor-block">
                          <input type="hidden" className="page-title" value={dbPageTitle} onChange={(e) => { changeSlug(e.target.value); }} placeholder="Title" />

                          <div id="post-title" placeholder="Title" contentEditable="true"
                            onKeyDown={(e) => {
                              changeTitle(e)
                              if (e.target.innerText.length > 0) {
                                setValidateTitle(false);
                              }
                            }}
                            onBlur={(e) => handleBlur(e)}
                            onKeyUp={(e) => changesTitles(e)}
                            onPaste={(e) => handlePaste(e)} >{dbPageTitle}</div>
                        </div>
                        {validateTitle === true ? (
                          <p className="validateError editor-error editor-error-space">The field is not filled</p>
                        ) : (
                          ""
                        )}

                        <div className={`newpageLeftRow textBlock ${validateEditorContent ? 'validateError inputError' : ''}`}>
                          <EditorComponent setUpdatedContent={setUpdatedContent} editorContent={editorContent} type="page" id={id}/>
                        </div>
                        {validateEditorContent === true ? (
                          <p className="validateError editor-error editor-error-space">The field is not filled</p>
                        ) : (
                          ""
                        )}

                        </>
                    }

                  </div>
                  <div className="newpageRight">
                    <div className="buttonsDesktop">

                      {(published === false && draft === false) ?
                        <div className="newpageRightButtons">
                          <button
                            onClick={async (e) => {
                              validateFields();
                              if (dbPageTitle.length > 0 && ((editorContent.length > 0) || (updatedContent.length > 0)) && pageImage !== '') {
                                setIsLoading(true);
                                await addData(true, "Published");
                                setStatus('Published');
                                setOpenNotify(true);
                                setPublished(true);
                                setPublicationList([...publicationList, { title: "Published" }]);

                                clearTimeout(notificationTimeoutId) 
                                notificationTimeoutId = setTimeout(() => {
                                  setOpenNotify(false)
                                }, 15000);
                              }
                            }
                            }
                          >
                            {isLoading ?
                              <div className="publishbutton-loader">
                                <div className="ring-loader"></div>
                              </div>
                              :
                              <>
                                <img src={send} alt="Send" />
                                <p>Publish</p>
                              </>}
                          </button>
                          <button
                            onClick={async (e) => {
                              draftValidateFields();
                              if (dbPageTitle.length > 0) {
                                setIsDraftLoading(true);
                                await addData(true, "Draft");
                                setStatus('Draft');
                                setDraft(true);
                                setDraftNotify(true)
                              }
                            }}>
                            {isDraftLoading ?
                              <div className="draftButton-loader">
                                <div className="ring-loader cancel"></div>
                              </div>
                              :
                              <>
                                Save as draft
                              </>}
                          </button>
                        </div>
                        :
                        ''}

                      {published === true ? (
                        <div>
                          <div className="newpageRightButtons" ref={dotsRef}>
                            <button onClick={(e) => {
                              validateFields();
                              if (dbPageTitle.length > 0 && ((editorContent.length > 0) || (updatedContent.length > 0)) && pageImage !== '') {
                                handlePublish(e)
                              }
                              if( isHomepage && ((editorContent.length > 0) || (updatedContent.length > 0)) && pageImage !== '' ) {
                                handlePublish(e)
                              }
                              if( isContactpage && pageImage !== '' ) {

                                if( validationContactpageData.publicEmailValid && validationContactpageData.receiveEmailValid ) {
                                  handlePublish(e);
                                } else {
                                  setValidationContactpageData((prevState) => ({
                                      ...prevState, // Spread the previous state
                                      showError: true // Update showError with the new value
                                  }));
                                }
                              }
                            }}>
                              {isLoading ?
                                <div className="updatebutton-loader">
                                  <div className="ring-loader"></div>
                                </div>
                                :
                                <>
                                  <p>Update</p>
                                </>}
                            </button>
                              
                            
                            
                            <button
                              className="newpageMoreButtons"
                              onClick={(e) => setIsPublishedOpen(!isPublishedOpen)}
                            >
                              <img src={dots} alt="Dots" className="action-dots" />
                            </button>

                            {isPublishedOpen && ! isHomepage && ! isContactpage ? (
                              <div className="publishedButtonsList">
                                <ul>
                                  <li className="publish-li"

                                    onClick={(e) => {
                                      draftValidateFields();
                                      if (dbPageTitle.length > 0) {
                                        handleChangeToDraft();
                                      }
                                    }}>Change to draft</li>
                                  <li className="publish-li" onClick={handelSingleDelete}>Delete</li>
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          {published === true ?
                            <p className="publishStatus">
                              <>Status: <span>{status}</span></>
                            </p>
                            :
                            ''
                          }
                        </div>
                      ) : (''

                      )}

                      {draft === true ? (
                        <div>
                          <div className="newpageRightButtons" ref={dotsRef}>
                            <button onClick={(e) => {
                              draftValidateFields();
                              if (dbPageTitle.length > 0) {
                                handleDraft(e)
                              }
                            }}>
                              {isLoading ?
                                <div className="updateDraftbutton-loader">
                                  <div className="ring-loader"></div>
                                </div>
                                :
                                <>
                                  <p>Update Draft</p>
                                </>}
                            </button>
                            <button
                              className="newpageMoreButtons"
                              onClick={(e) => setIsDraftOpen(!isDraftOpen)}
                            >
                              <img src={dots} alt="Dots" className="action-dots" />
                            </button>

                            {isDraftOpen ? (
                              <div className="publishedButtonsList">
                                <ul>
                                  <li className="publish-li" onClick={(e) => {
                                    validateFields();
                                    if (dbPageTitle.length > 0 && ((editorContent.length > 0) || (updatedContent.length > 0)) && pageImage !== '') {
                                      handleChangeToPublish(e, () => setIsLoading(true))
                                    }
                                  }}>Publish</li>
                                  <li className="publish-li" onClick={handelSingleDelete}>Delete</li>
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          {draft === true ? (
                            <p className="publishStatus">
                              Status: <span className="draftStatus">Draft</span>
                            </p>
                          ) : (
                            ''
                          )}
                        </div>
                      ) : ''}
                    </div>

                    <div className="newPageRightRow">
                      <p className="newPageRightTitle iconDropWrap" onClick={toggleDropdown1}>
                        <span>
                          URL address
                        </span>
                        <div className="dropdownIcon">
                          <img src={iconDropDown} alt="dropdown" />
                        </div>
                      </p>
                      <div className={`contentDrop ${isOpen1 ? 'expanded' : 'collapse'}`}>
                        <p className="newPageRightFullUrl">
                          <span>Display at the end of the publication...</span>
                        </p>
                        <div className="adressInputBlock">
                          <img className="adressLink" src={link} alt="Link" />
                          <input
                            id="page-slug"
                            type="text"
                            placeholder="URL"
                            readOnly={isHomepage || isContactpage ? true : false}
                            value={isHomepage ? 'home' : url}
                            onChange={(e) => {
                              if( isHomepage || isContactpage ) {
                                return false;
                              }
                              setUrl(e.target.value);
                              setIslugChanged(true)
                              if (e.target.value === '') {
                                setIslugChanged(false)
                              }
                            }}
                            onBlur={(e) => {
                              if( isHomepage || isContactpage ) {
                                return false;
                              }
                              let urlValue = convertToSlug(e.target.value);
                              setUrl(urlValue);
                              if (e.target.value === '') {
                                changeSlug(pageTitle);
                                setIslugChanged(false)
                              }
                            }}
                          />
                        </div>
                        <p className="newPageRightFullUrl slug-box">
                          Full URL:
                          {
                            published === true && status == 'Published' ? (
                              <Link className="pageurl" target={'_blank'} to={`${baseUrl}${url}`}>
                                <span>{baseUrl}{url}</span>
                                <img className="link-external" src={linkExternal} />
                              </Link>
                            ) : (
                              <span className="pageurl">{baseUrl}{url}</span>
                            )
                          }
                        </p>
                      </div>
                    </div>

                    { ! isHomepage && ! isContactpage &&
                      <div className="newPageRightRow categoryFieldDrop">
                        <p className="newPageRightTitle iconDropWrap" onClick={toggleDropdown4}>
                            <span>Categories (optional)</span>
                            <div className="dropdownIcon">
                              <img src={iconDropDown} alt="dropdown" />
                            </div>
                        </p>

                        <div className={`contentDrop ${isOpen4 ? 'expanded' : 'collapse'}`}>
                            <p className="newPageRightFullUrl">
                              <span>Display at the end of the publication...</span>
                            </p>
                            <div className="adressInputBlock urlInput">
                              <img className="adressLink" src={search} alt="Search" />
                              <input
                                type="text"
                                placeholder="Search"
                                value={searchCategory}
                                onChange={(e) => setSearchCategory(e.target.value)}
                                onKeyPress={handleKeyPress}
                              />
                              <ul className="list-search categories-list">
                                {
                                  filteredValues
                                    .filter((category) => category.toLowerCase().includes(searchCategory.toLowerCase()))
                                    .map((category, index) => (
                                      <li key={index}>
                                        <label>
                                          <input 
                                            type="checkbox"
                                            checked={selectedCategories.includes(category)}
                                            onChange={() => handleCheckboxChange(category)}
                                          />
                                          <p>{category}</p>
                                        </label>
                                      </li>
                                    ))
                                }
                              </ul> 
                              
                              <button className="new-category" onClick={handleNewCategoryClick}>
                                <img src={plus} alt="Plus" /> New Category
                              </button>
                          </div>
                        </div>
                      </div>
                    }

                    <div className="newPageRight desktopFeatures">
                      <p className="newPageRightTitle iconDropWrap" onClick={toggleDropdown3}>
                        <span>
                        Featured image
                        </span>
                        <div className="dropdownIcon">
                          <img src={iconDropDown} alt="dropdown" />
                        </div>
                      </p>
                      <div className={`contentDrop ${isOpen3 ? 'expanded' : 'collapse'}`}>
                      <p className="newPageRightFullUrl">
                        <span>Display at the end of the publication...</span>
                      </p>
                      <div
                        className="newPageRightRow imageUploadBox"
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                      >
                        {saveFeatureImage ? (
                          <div
                            id="newpageImageUpload"
                            className={ isDragging ? "draging newpageImageUpload" : "newpageImageUpload" }
                            onClick={handleClick}
                          >
                            {loadingFeatureImg === true &&
                              featuredImageModal == false ? (
                              <div className="featureImg-loader">
                                <Loader />
                              </div>
                            ) : (
                              <>
                                <div onClick={handleClick}>
                                  <img
                                    className="newpage_CoverImg"
                                    src={featureImage}
                                    onLoad={handleImageLoad}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        ) : (
                          <div
                            id="newpageImageUpload"
                            className={ isDragging ? "draging newpageImageUpload" : "newpageImageUpload" }
                            onClick={() => {
                              setShowUploadMediaPopup(true);
                              setUploadMediaPopupFor('feature-image');
                              setUploadMediaPopupTab('upload');
                            }}
                          >
                            {loadingFeatureImg === true &&
                              featuredImageModal === false ? (
                              <div className="featureImg-loader">
                                <Loader />
                              </div>
                            ) : (
                              <div className="featureImgMiddleContent">
                                <img
                                  className="fileUploadCover_Img"
                                  src={featured}
                                  alt="Featured Image"
                                />
                                <p className="newPageImageUploadText">                                
                                  <span>Upload</span> or <span onClick={(e) => {e.stopPropagation(); setShowUploadMediaPopup(true); setUploadMediaPopupFor('feature-image'); setUploadMediaPopupTab('media');}}>Open Media Library</span>
                                    <br />
                                    JPG, PNG (max. 128MB)
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                        <input
                          type="file"
                          id="feature-img"
                          ref={fileInputRef}
                          onChange={(e) => {
                            handleFileUpload(e);
                            setValidateImage(false);
                          }}

                          accept="image/*"
                          hidden
                        />
                      </div>
                      {validateImage === true ? (
                        <p className="validateError">The field is not filled</p>
                      ) : (
                        ""
                      )}
                      </div>
                    
                    </div>
                    <div className="buttonsMobile">

                      {(published === false && draft === false) ?
                        <div className="newpageRightButtons">
                          <button
                            onClick={async (e) => {
                              validateFields();
                              if (dbPageTitle.length > 0 && ((editorContent.length > 0) || (updatedContent.length > 0)) && pageImage !== '') {
                                setIsLoading(true);
                                await addData(true, "Published");
                                setOpenNotify(true);
                                setPublished(true);
                                setPublicationList([...publicationList, { title: "Published" }]);

                                clearTimeout(notificationTimeoutId) 
                                notificationTimeoutId = setTimeout(() => {
                                  setOpenNotify(false)
                                }, 15000);
                              }
                            }
                            }
                          >
                            {isLoading ?
                              <div className="publishbutton-loader">
                                <div className="ring-loader"></div>
                              </div>
                              :
                              <>
                                <img src={send} alt="Send" />
                                <p>Publish</p>
                              </>}
                          </button>
                          <button
                            onClick={async (e) => {
                              draftValidateFields();
                              if (dbPageTitle.length > 0) {
                                setIsDraftLoading(true);
                                await addData(true, "Draft");
                                setDraft(true);
                                setDraftNotify(true);
                              }
                            }}>
                            {isDraftLoading ?
                              <div className="draftButton-loader">
                                <div className="ring-loader cancel"></div>
                              </div>
                              :
                              <>
                                Save as draft
                              </>}
                          </button>
                        </div>
                        :
                        ''}

                      {published === true ? (
                        <div>
                          <div className="newpageRightButtons" ref={dotsRef}>
                            <button onClick={(e) => {
                              validateFields();
                              if (dbPageTitle.length > 0 && ((editorContent.length > 0) || (updatedContent.length > 0)) && pageImage !== '') {
                                handlePublish(e)
                              }
                              if( isHomepage && ((editorContent.length > 0) || (updatedContent.length > 0)) && pageImage !== '' ) {
                                handlePublish(e)
                              }
                            }}>
                              {isLoading ?
                                <div className="updatebutton-loader">
                                  <div className="ring-loader"></div>
                                </div>
                                :
                                <>
                                  <p>Update</p>
                                </>}
                            </button>
                            <button
                              className="newpageMoreButtons"
                              onClick={(e) => setIsPublishedOpen(!isPublishedOpen)}
                            >
                              <img src={dots} alt="Dots" />
                            </button>
                            {isPublishedOpen && ! isHomepage && ! isContactpage ? (
                              <div className="publishedButtonsList">
                                <ul>
                                  <li className="publish-li" onClick={handleChangeToDraft}>Change to draft</li>
                                  <li className="publish-li" onClick={handelSingleDelete}>Delete</li>
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {published === true ? (
                            <p className="publishStatus">
                              Status: <span>{status}</span>
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}

                      {draft === true ? (
                        <div>
                          <div className="newpageRightButtons" ref={dotsRef}>
                            <button onClick={(e) => {
                              draftValidateFields();
                              if (dbPageTitle.length > 0) {
                                handleDraft(e)
                              }
                            }}>
                              {isLoading ?
                                <div className="updateDraftbutton-loader">
                                  <div className="ring-loader"></div>
                                </div>
                                :
                                <>
                                  <p>Update Draft</p>
                                </>}
                            </button>
                            <button
                              className="newpageMoreButtons"
                              onClick={(e) => setIsDraftOpen(!isDraftOpen)}
                            >
                              <img src={dots} alt="Dots" className="action-dots" />
                            </button>

                            {isDraftOpen ? (
                              <div className="publishedButtonsList">
                                <ul>
                                  <li className="publish-li" onClick={(e) => {
                                    validateFields();
                                    if (dbPageTitle.length > 0 && ((editorContent.length > 0) || (updatedContent.length > 0)) && pageImage !== '') {
                                      handleChangeToPublish(e, () => setIsLoading(true))
                                    }
                                  }}>Publish</li>
                                  <li className="publish-li" onClick={handelSingleDelete}>Delete</li>
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <p className="publishStatus">
                            Status: <span className="draftStatus">Draft</span>
                          </p>
                        </div>
                      ) : (
                        ""
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            {showUploadMediaPopup && 
              <UploadMediaPopup showUploadMediaPopup={showUploadMediaPopup} setShowUploadMediaPopup={setShowUploadMediaPopup} setSelectedProfileImage={setSelectedFeatureImage} selectMediaLoading={selectMediaLoading} uploadMediaPopupFor={uploadMediaPopupFor} uploadMediaPopupTab={uploadMediaPopupTab}/>
            }
            {(isPopupOpenDeleteAccount || showCategoryField) && <div className="welcomeOverlay"></div>}
            <SingleDeletePage isOpen={isPopupOpenDeleteAccount} onClose={closeAccountDeletedPopup} type="page" name="pages" data={data} index={newPageID} />
            <AddCategoryPage isOpen={showCategoryField} onClose={closeCategoryField} data={values} setCategory={setCategories} setSelectedCategory={setSelectedCategories} setOpenNotify={setCategoriesNotify} />

            {categoriesNotify && 
              <NotificationMessage  openNotify={categoriesNotify} setOpenNotify={setCategoriesNotify} type={'success'} title={`Category has been successfully added!`} />
            }
            {featuredImageModal && (
              <>
                <div className="imagCropModal"  id="deleteMedia">
                  <div className="featuredImageModal featurboxModal" ref={modalRef}>
                    <div className="modal-content">
                      {uploadedImage === null ? (
                        <div
                          className="newPageRightRow"
                          onDragOver={handleDragOver}
                          onDragLeave={handleDragLeave}
                          onDrop={handleDrop}
                        >
                          {((saveFeatureImage && saveFeatureImage === oldImage) ||
                            saveFeatureImage !== featureImage) &&
                            (saveFeatureImage ? (
                              <div
                                id="newpageImageUpload"
                                className={ isDragging ? "draging newpageImageUpload" : "newpageImageUpload"
                                }
                              >
                                {loadingFeatureImg === true ? (
                                  <div className="featureImg-loader">
                                    <Loader />
                                  </div>
                                ) : (
                                  <>
                                    <div>
                                      <div className="crop-container">
                                        <ImageEditor
                                          type="featureImg"
                                          image={featureImage}
                                          oldImage={oldImage}
                                          setOldImage={setOldImage}
                                          setFeatureImage={setFeatureImage}
                                          setUploadedImage={setUploadedImage}
                                          replaceImage={handleFileUpload}
                                          zoom={zoom}
                                          position={position}
                                          setZoom={handleZoom}
                                          setPosition={handlePosition}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            ) : (
                              <div
                                id="newpageImageUpload"
                                className={ isDragging ? "draging newpageImageUpload" : "newpageImageUpload" }
                                onClick={() =>{
                                  setShowUploadMediaPopup(true);
                                  setUploadMediaPopupFor('feature-image');
                                  setUploadMediaPopupTab('upload');
                                }}
                              >
                                {loadingFeatureImg === true ? (
                                  <div className="featureImg-loader">
                                    <Loader />
                                  </div>
                                ) : (
                                  <>
                                    <div className="featureImgMiddleContent">
                                      <img
                                        className="fileUploadCover_Img"
                                        src={featured}
                                        alt="Featured Image"
                                      />
                                      <p className="newPageImageUploadText">                                      
                                        <span>Upload</span> or <span onClick={(e) => {e.stopPropagation(); setShowUploadMediaPopup(true); setUploadMediaPopupFor('feature-image'); setUploadMediaPopupTab('media');}}>Open Media Library</span>
                                        <br />
                                        JPG, PNG (max. 128MB)
                                      </p>
                                    </div>
                                  </>
                                )}
                              </div>
                            ))}

                          {featureImage &&
                            saveFeatureImage === featureImage &&
                            (featureImage ? (
                              <div
                                id="newpageImageUpload"
                                className={ isDragging ? "draging newpageImageUpload" : "newpageImageUpload"
                                }
                              >
                                {loadingFeatureImg === true ? (
                                  <div className="featureImg-loader">
                                    <Loader />
                                  </div>
                                ) : (
                                  <>
                                    <div>
                                      <div className="crop-container">
                                        <ImageEditor
                                          type="featureImg"
                                          image={featureImage}
                                          oldImage={oldImage}
                                          setOldImage={setOldImage}
                                          setFeatureImage={setFeatureImage}
                                          setUploadedImage={setUploadedImage}
                                          replaceImage={handleFileUpload}
                                          zoom={zoom}
                                          position={position}
                                          setZoom={handleZoom}
                                          setPosition={handlePosition}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            ) : (
                              <div
                                id="newpageImageUpload"
                                className={ isDragging ? "draging newpageImageUpload" : "newpageImageUpload" }
                                onClick={() =>{                                  
                                  setShowUploadMediaPopup(true);
                                  setUploadMediaPopupFor('feature-image');
                                  setUploadMediaPopupTab('upload');
                                }}
                              >
                                {loadingFeatureImg === true ? (
                                  <div className="featureImg-loader">
                                    <Loader />
                                  </div>
                                ) : (
                                  <>
                                    <div className="featureImgMiddleContent">
                                      <img
                                        className="fileUploadCover_Img"
                                        src={featured}
                                        alt="Featured Image"
                                      />
                                      <p className="newPageImageUploadText">
                                        <span>Upload</span> or <span onClick={(e) => {e.stopPropagation(); setShowUploadMediaPopup(true); setUploadMediaPopupFor('feature-image'); setUploadMediaPopupTab('media');}}>Open Media Library</span>
                                        <br />
                                        JPG, PNG (max. 128MB)
                                      </p>
                                    </div>
                                  </>
                                )}
                              </div>
                            ))}
                          <input
                            type="file"
                            id="feature-img"
                            onChange={handleFileUpload}
                            accept="image/*"
                            hidden
                          />
                        </div>
                      ) : (
                        <div>
                          <div className="crop-container">
                            {featureImage && oldImage && (
                              <ImageEditor
                                type="featureImg"
                                image={featureImage}
                                oldImage={oldImage}
                                setFeatureImage={setFeatureImage}
                                setUploadedImage={setUploadedImage}
                                replaceImage={handleFileUpload}
                                zoom={zoom}
                                position={position}
                                setZoom={handleZoom}
                                setPosition={handlePosition}
                              />
                            )}
                          </div>
                        </div>
                      )}
                      <div className="replaceImageRange">
                        <div className="replaceImgBtn">
                          <button
                            className="uploadImage"
                            type="button"
                            onClick={() => handleReplaceImg()}
                          >
                            <span>Replace Image</span>
                          </button>
                        </div>
                        <div className="imgRange">
                          <img className="coverImgLeft" src={coverImg} />
                          <input
                            id="zoom-range"
                            className="zoom-range"
                            type="range"
                            onMouseDown={handleMouseDown}
                            onMouseUp={handleMouseUp}
                            onTouchStart={handleTouchStart}
                            onTouchEnd={handleTouchEnd}
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aspect={384 / 200}
                            aria-labelledby="Zoom"
                            style={{
                              background: `linear-gradient(to right, #344054 ${((zoom - 1) / 2) * 100
                                }%, #B7BCC2 0%)`,
                            }}
                            onChange={(e) => {
                              handleZoom(e.target.value);
                            }}
                          />
                          <img className="coverImgRight" src={coverImg} />
                        </div>
                      </div>
                      <div className="featuredImageModalbtn">
                        <button
                          className="cancel"
                          type="button"
                          onClick={() => handleCancel()}
                        >
                          Cancel
                        </button>
                        <button
                          className="savePicture"
                          type="button"
                          onClick={() => handleSavePicture()}
                        >
                          Save Picture
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {featuredImageModal && <div className="overlayModal"> </div>}
              </>
            )}
            {
              <Notification
                title={"Your page has been successfully updated! "}
                text={"You can view it"}
                link= {"here."}
                to={`/dashboard/page/${newPageID}`}
                setOpenNotify={setOpenNotifyDraft}
                type={"success"}
                openNotify={openNotifyDraft}
              />
            }
            {
              <Notification
                title={"Your page has been successfully published!"}
                text={"You can view it "}
                link= {"here."}
                to={`/dashboard/page/${newPageID}`}
                setOpenNotify={setOpenNotify}
                type={"success"}
                openNotify={openNotify}
              />
            }
            {draftNotify && 
              <NotificationMessage  openNotify={draftNotify} setOpenNotify={setDraftNotify} type={'success'} title={`Your page has been saved as a draft!`} />
            }
            {subscriberAddedNotify && (
                <NotificationMessage  openNotify={subscriberAddedNotify} setOpenNotify={setSubscriberAddedNotify} type={'success'} title={messages} name="liveNotification" />
            )}
          </section>
            :
              canChkAccess && <Error menuDisabled={true} />
      }
    </>
  );
}

export default NewPage;
